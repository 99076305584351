import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      resolve()
    })
  },
  /**
   * 一覧データを取得する
   *
   * @param {object} queryParams
   * @return {object}
   */
  async fetchAll({ commit }, queryParams) {
    const response = await api.get('manage/export/', {
      params: queryParams,
    })
    commit('setData', response.data)
  },
  /**
   * エクスポートデータを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postExportData({}, formData) {
    try {
      await api.post('manage/export/', formData)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ state }, id) {
    try {
      await api.delete('manage/export/' + id + '/')
      const index = state.data.results.findIndex((result) => result.id === id)
      state.data.results.splice(index, 1)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
