import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  users: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      resolve()
    })
  },
  /**
   * データ一覧を取得する
   *
   * @param {object} queryParams
   * @return {void}
   */
  async fetchAll({ commit }, queryParams) {
    const response = await api.get('dashboard/users/', {
      params: queryParams,
    })
    commit('setData', response.data)
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {void}
   */
  async postData({}, formData) {
    try {
      await api.post('dashboard/users/', formData)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {void}
   */
  async patchData({}, formData) {
    try {
      await api.patch('dashboard/users/' + formData.id + '/', formData)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {void}
   */
  async postOrPatch({ dispatch }, formData) {
    // 必要なデータのみにする
    const requiredKeys = [
      'id',
      'username',
      'password',
      'last_name',
      'first_name',
      'group_id',
      'is_active',
    ]
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      if ('id' in submitData && submitData['id']) {
        await dispatch('patchData', submitData)
      } else {
        await dispatch('postData', submitData)
      }
    } catch (error) {
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ state }, id) {
    try {
      await api.delete('dashboard/users/' + id + '/')
      const index = state.data.results.findIndex((result) => result.id === id)
      state.data.results.splice(index, 1)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
