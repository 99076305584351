<template>
  <Navbar>
    <template v-slot:navbarTitle>在庫管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-graph-up me-1"></i>変動履歴一覧</h5>
      <div class="ms-auto">
        <button
          type="button"
          v-on:click.prevent="convertToCsvConfirm()"
          class="btn btn-dark"
        >
          <span class="d-block d-sm-none"
            ><i class="bi bi-arrow-repeat"></i
          ></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-arrow-repeat me-1"></i>データをCSVに変換する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <search-form
        :query="query"
        query-key="code"
        placeholder="品目コードで検索"
        @update-search-query="onUpdateSearchQuery"
        @open-search-modal="onOpenSearchModal"
      ></search-form>
      <Pagination :prev="prev" :next="next" @move-page="movePage" />
    </div>

    <!-- transactions -->
    <div v-if="isLoading && transactions && transactions.length > 0">
      <data-table :headers="dataHeaders" :items="transactions" />
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>

    <Confirm ref="confirmConvertData" @confirm-true="convertToCsv" />
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import Confirm from '@/components/TheConfirm.vue'
import DataTable from '@/components/common/DataTable.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'

export default {
  name: 'ManageStockTransactionList',
  components: {
    Navbar,
    Pagination,
    Confirm,
    DataTable,
    SearchForm,
    SearchModal,
  },

  data() {
    return {
      query: {},
      isLoading: false,
      dataHeaders: [
        {
          key: 'transaction_date',
          label: '変動日時',
          class: 'col-1',
          format: 'string',
        },
        {
          key: 'code',
          label: '品目コード',
          class: 'col-1',
          format: 'code',
        },
        {
          key: 'item_model',
          label: '型式',
          class: 'col-1',
          format: 'code',
        },
        {
          key: 'name',
          label: '品目名',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'location_name',
          label: '保管場所名',
          class: 'col-1',
          format: 'string',
        },
        {
          key: 'quantity',
          label: '変動数量',
          class: 'col-1',
          format: 'number',
        },
        {
          key: 'current_quantity',
          label: '在庫数量',
          class: 'col-1',
          format: 'number',
        },
        {
          key: 'reason',
          label: '変動理由',
          class: 'col-2',
          format: 'string',
        },
        {
          key: 'display_name',
          label: 'ユーザー',
          class: 'col-1',
          format: 'username',
        },
      ],
    }
  },
  computed: {
    transactions() {
      return store.getters['manage_stock_transaction/results']
    },
    prev() {
      return store.getters['manage_stock_transaction/prev']
    },
    next() {
      return store.getters['manage_stock_transaction/next']
    },
    locationList() {
      let locationList = store.getters['manage_stock_location/list']
      return locationList.map((item) => {
        return {
          value: item.id,
          name: item.name,
        }
      })
    },
    searchItems() {
      return [
        {
          id: 'transaction_date',
          label: '変動日',
          type: 'date',
        },
        {
          id: 'code',
          label: '品目コード（前方一致）',
          type: 'text',
        },
        {
          id: 'item_model',
          label: '型式（前方一致）',
          type: 'text',
        },
        {
          id: 'name',
          label: '品目名（部分一致）',
          type: 'text',
        },
        {
          id: 'stock_location_id',
          label: '保管場所',
          type: 'select',
          options: this.locationList,
        },
      ]
    },
  },

  async created() {
    await this.updateQueryFromRoute()
    await this.getQueryResult()
    await this.getLocationList()
  },

  methods: {
    ...mapActions({
      postExportData: 'export_data/postExportData',
    }),
    getData(query = {}) {
      return store.dispatch('manage_stock_transaction/fetchAll', query)
    },
    getLocationList() {
      return store.dispatch('manage_stock_location/fetchList')
    },
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    updateQueryFromRoute() {
      this.query = this.$route.query
      this.$router.replace({
        path: this.$route.path,
        query: {},
      })
    },
    async getQueryResult() {
      await this.getData(this.query)
      this.isLoading = true
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    onUpdateSearchQuery(query = {}) {
      this.query = query
      this.getQueryResult()
    },
    convertToCsvConfirm() {
      this.$refs.confirmConvertData.showConfirm(
        'データを変換',
        'この内容でCSVに変換しますか？'
      )
    },
    convertToCsv() {
      const formData = {
        application: 'stock-transactions',
        conditions: Object.assign({}, this.query),
      }
      try {
        this.postExportData(formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message:
            'CSVファイルへの変換を開始しました。エクスポート管理からダウンロードしてください',
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
