<template>
  <Navbar>
    <template v-slot:navbarButton>
      <button @click="resume" class="btn btn-danger">中断</button>
    </template>
  </Navbar>

  <main id="main" class="bg-light">
    <div class="container pt-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">検査開始</h5>
          <h6 class="card-subtitle mb-2 text-muted"></h6>
          <hr />
          <div class="mb-4">
            <label class="text-muted mb-1" for="worker">検査員</label>
            <input
              v-model="worker"
              type="text"
              class="form-control form-control-lg"
              id="worker"
              readonly
            />
          </div>
          <div class="mb-4">
            <label class="text-muted mb-1" for="inspectionName"
              >検査シート</label
            >
            <input
              v-model="form_data.inspection_name"
              type="text"
              class="form-control form-control-lg"
              id="inspectionName"
              readonly
            />
          </div>
          <div class="mb-4">
            <label class="text-muted mb-1" for="inspectionResultNumber"
              >検査番号</label
            >
            <input
              v-model="form_data.inspection_result_number"
              type="text"
              class="form-control form-control-lg"
              id="inspectionResultNumber"
              readonly
            />
          </div>
          <div>
            <label class="text-muted mb-1" for="beginDatetime"
              >検査開始日時</label
            >
            <input
              v-model="form_data.begin_datetime"
              type="datetime-local"
              class="form-control form-control-lg"
              id="beginDatetime"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <div class="container pt-5">
      <div class="row row-cols-1 g-3">
        <div class="col">
          <button
            class="btn btn-lg btn-dark w-100 py-4"
            v-on:click.prevent="formSubmit()"
          >
            次へ
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { inject } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/apps/inspection/operate/components/InspectionNavbar.vue'

export default {
  name: 'InspectionStart',
  components: {
    Navbar,
  },

  data() {
    return {
      form_data: {},
    }
  },

  computed: {
    ...mapGetters({
      userData: 'auth/userData',
      inspection_result: 'inspection_result/detail',
    }),
    worker: function () {
      return this.userData('display_name')
    },
  },

  created() {
    const dayjs = inject('dayjs')
    this.form_data = {
      inspection_type_name: this.inspection_result.inspection_type_name,
      inspection_name: this.inspection_result.inspection_name,
      inspection_result_number: this.inspection_result.inspection_result_number,
      begin_datetime: dayjs().format('YYYY-MM-DDTHH:mm'),
    }
  },

  methods: {
    ...mapActions({
      postData: 'inspection_result/postData',
      postTempData: 'inspection_result/postTempData',
      postPlanResultData: 'inspection_plan/postResultData',
      deleteNumber: 'inspection/deleteInspectionNumber',
    }),
    async formSubmit() {
      try {
        await this.postData(this.form_data)
        await this.postPlanResultData()
        await this.postTempData()
        this.$router.push({
          name: 'InspectionPlanResult',
        })
      } catch (error) {
        // 検査番号を削除
        await this.deleteNumber()

        this.$store.dispatch('alert/setErrorMessage', {
          message:
            '開始保存中にエラーが発生しました。もう一度はじめからやり直してください。',
        })
        window.setTimeout(() => {
          this.$store.dispatch('clearDataStores')
          this.$router.push({
            name: 'InspectionPlan',
          })
        }, this.$store.getters['alert/timeout'])
      }
    },
    async resume() {
      await this.deleteNumber()
      this.$router.push({
        name: 'InspectionPlan',
      })
    },
  },
}
</script>
