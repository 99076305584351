import OperateInspectionPlanHome from './views/Home.vue'
import OperateInspectionPlan from './views/InspectionPlan.vue'
import OperateInspectionPlanDetail from './views/InspectionPlanDetail.vue'
import OperateInspectionPlanCall from './views/InspectionCall.vue'
import OperateInspectionPlanStart from './views/InspectionStart.vue'
import OperateInspectionPlanResult from './views/InspectionResult.vue'
import OperateInspectionPlanResume from './views/InspectionResume.vue'
import OperateInspectionPlanEnd from './views/InspectionEnd.vue'
import OperateInspectionPlanRestart from './views/InspectionRestart.vue'
import OperateInspectionPlanTemp from './views/InspectionTemp.vue'
import OperateInspectionPlanTempDetail from './views/InspectionTempDetail.vue'

export default {
  routes: [
    {
      path: '/operate/inspection_plan',
      component: OperateInspectionPlanHome,
      name: 'InspectionPlanHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'plans',
          component: OperateInspectionPlan,
          name: 'InspectionPlan',
        },
        {
          path: 'detail',
          component: OperateInspectionPlanDetail,
          name: 'InspectionPlanDetail',
        },
        {
          path: 'calls',
          component: OperateInspectionPlanCall,
          name: 'InspectionPlanCall',
        },
        {
          path: 'start',
          component: OperateInspectionPlanStart,
          name: 'InspectionPlanStart',
        },
        {
          path: 'result',
          component: OperateInspectionPlanResult,
          name: 'InspectionPlanResult',
        },
        {
          path: 'resume',
          component: OperateInspectionPlanResume,
          name: 'InspectionPlanResume',
        },
        {
          path: 'end',
          component: OperateInspectionPlanEnd,
          name: 'InspectionPlanEnd',
        },
        {
          path: 'restart',
          component: OperateInspectionPlanRestart,
          name: 'InspectionPlanRestart',
        },
        {
          path: 'temps',
          component: OperateInspectionPlanTemp,
          name: 'InspectionPlanTemp',
        },
        {
          path: 'temps/:id([0-9]+)',
          component: OperateInspectionPlanTempDetail,
          name: 'InspectionPlanTempDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },
  ],
}
