<template>
  <Navbar>
    <template v-slot:navbarButton>
      <router-link
        :to="{
          name: 'InspectionCode',
        }"
        class="btn btn-dark"
        >戻る</router-link
      >
    </template>
  </Navbar>

  <main id="main" class="bg-light">
    <div class="container pt-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">検査シート一覧</h5>
          <h6 class="card-subtitle mb-2 text-muted"></h6>
          <hr />
          <!-- calls -->
          <div v-if="results && results.length > 0">
            <div class="table-responsive">
              <table class="table table-bordered bg-white">
                <thead>
                  <tr>
                    <th scope="col" class="col-sm-2">型式・型番</th>
                    <th scope="col" class="col-sm-2">検査種別</th>
                    <th scope="col" class="col-sm-7">検査シート</th>
                    <th scope="col" class="col-sm-1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(result, index) in results" :key="index">
                    <td scope="row" class="text-center">{{ result.code }}</td>
                    <td class="text-center">
                      {{ result.inspection_type_name }}
                    </td>
                    <td>{{ result.inspection_name }}</td>
                    <td class="text-end">
                      <a
                        href="#"
                        @click="selectedInspection(index)"
                        class="btn btn-primary"
                        >開始</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <Pagination
                :count="count"
                :prev="prev"
                :next="next"
                :current_page="current_page"
                :total_pages="total_pages"
                :page_range="0"
                :show_count="true"
                @move-page="movePage"
              />
            </div>
          </div>
          <p v-else>該当するデータはありません。</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Pagination from '@/components/ThePagination.vue'
import Navbar from '../components/InspectionNavbar.vue'

export default {
  name: 'InspectionCall',
  components: {
    Navbar,
    Pagination,
  },

  computed: {
    ...mapGetters({
      results: 'inspection_call/results',
      count: 'inspection_call/count',
      prev: 'inspection_call/prev',
      next: 'inspection_call/next',
      current_page: 'inspection_call/current_page',
      total_pages: 'inspection_call/total_pages',
      query: 'inspection_call/query_params',
    }),
  },

  async created() {
    await this.getData()
  },

  methods: {
    ...mapMutations({
      setInspection: 'inspection/setDetail',
      setInspectionResult: 'inspection_result/setDetail',
      setCode: 'inspection_call/setCode',
    }),
    ...mapActions({
      setQuery: 'inspection_call/setQuery',
      getData: 'inspection_call/fetchList',
      generateNumber: 'inspection/postGenerateNumber',
    }),
    movePage(page) {
      this.setQuery(Object.assign(this.query, { page: page })).then(() => {
        this.getData()
      })
    },
    async selectedInspection(index) {
      let inspection = {
        inspection_type_id: this.results[index].inspection_type_id,
        inspection_type_name: this.results[index].inspection_type_name,
        inspection_id: this.results[index].inspection_id,
        inspection_name: this.results[index].inspection_name,
      }
      await this.setInspection(inspection)
      await this.setCode(this.results[index].code)
      await this.generateNumber().then((response) => {
        this.setInspectionResult({
          inspection_type_name: inspection.inspection_type_name,
          inspection_name: inspection.inspection_name,
          inspection_result_number_id: response.id,
          inspection_result_number: response.inspection_result_number,
        })
        this.$router.push({
          name: 'InspectionStart',
        })
      })
    },
  },
}
</script>
