import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  detail: {},
  list: [],
  query: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  detail: (state) => state.detail,
  list: (state) => state.list,
  query: (state) => state.query,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
  setQuery: (state, query) => (state.query = query),
  clearQuery: (state) => (state.query = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      commit('clearList')
      commit('clearQuery')
      resolve()
    })
  },
  async fetchAll({ commit, state }) {
    const response = await api.get('manage/clients/', {
      params: state.query,
    })
    commit('setData', response.data)
  },
  async fetchData({ commit }, id) {
    const response = await api.get('manage/clients/' + id + '/')
    commit('setDetail', response.data)
  },
  async fetchList({ commit }, query_params) {
    const response = await api.get('manage/clients/list/', {
      params: query_params,
    })
    commit('setList', response.data)
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ state }, formData) {
    try {
      const response = await api.post('manage/clients/', formData)
      state.data.results.push(response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({ state, commit }, formData) {
    try {
      const response = await api.patch(
        'manage/clients/' + formData.id + '/',
        formData
      )
      commit('setDetail', Object.assign(state.detail, response.data))
    } catch (error) {
      throw error
    }
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {object}
   */
  async postOrPatch({ dispatch }, formData) {
    // 必要なデータのみにする
    const requiredKeys = [
      'id',
      'code',
      'name',
      'kana',
      'postal_code',
      'address',
      'phone_number',
      'email',
      'memo',
    ]
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      if ('id' in submitData && submitData['id']) {
        await dispatch('patchData', submitData)
      } else {
        await dispatch('postData', submitData)
      }
    } catch (error) {
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ state }, id) {
    try {
      await api.delete('manage/clients/' + id + '/')
      const index = state.data.results.findIndex((result) => result.id === id)
      state.data.results.splice(index, 1)
    } catch (error) {
      throw error
    }
  },
  /**
   * CSVファイルをインポートする
   *
   * @param {object}
   * @return {object}
   */
  async importData({}, formData) {
    try {
      await api.post('manage/clients/import/', formData)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
