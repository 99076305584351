import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  detail: {},
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  inspections: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  detail: (state) => state.detail,
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      commit('clearList')
      resolve()
    })
  },
  async fetchAll({ commit }, query_params) {
    // パラメーターの値が空の場合は削除する
    Object.keys(query_params).forEach(
      (key) => query_params[key] === '' && delete query_params[key]
    )
    const response = await api.get('manage/inspections/', {
      params: query_params,
    })
    commit('setData', response.data)
  },
  async fetchList({ commit }) {
    const response = await api.get('manage/inspections/list/')
    commit('setList', response.data)
  },
  async fetchData({ state, commit }, id) {
    if (Object.keys(state.detail).length > 0 && state.detail.id == id) {
      return
    }

    const response = await api.get('manage/inspections/' + id + '/')
    commit('setDetail', response.data)
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ commit }, form_data) {
    const response = await api.post('manage/inspections/', form_data)
    commit('setDetail', response.data)
    return response.data
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({ commit }, form_data) {
    const response = await api.patch(
      'manage/inspections/' + form_data.id + '/',
      form_data
    )
    commit('setDetail', response.data)
    return response.data
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {object}
   */
  async postOrPatch({ state, commit, dispatch }, form_data) {
    // 必要なデータのみにする
    const required_keys = ['id', 'name']
    const submit_data = Object.fromEntries(
      Object.entries(form_data).filter(([key, value]) =>
        required_keys.includes(key)
      )
    )

    if ('id' in submit_data && submit_data['id']) {
      await dispatch('relatedDataDelete', form_data)
      await dispatch('patchData', submit_data)
    } else {
      await dispatch('postData', submit_data)
    }
    await dispatch('relatedDataPost', form_data)
    const detail = state.detail
    await commit('clearDetail')
    return detail
  },
  /**
   * 関連データを作成または更新する
   *
   * @param {object}
   * @return {void}
   */
  async relatedDataPost({ dispatch }, form_data) {
    // 検査項目
    if ('items' in form_data) {
      dispatch('inspection_item/bulkPostOrPatch', form_data['items'], {
        root: true,
      })
    }
  },
  /**
   * 関連データを削除する
   *
   * @param {object}
   * @return {void}
   */
  async relatedDataDelete({ state, dispatch }, form_data) {
    // 検査項目
    if ('items' in form_data) {
      const diff_item_ids = getDiffIds(state.detail.items, form_data['items'])
      if (diff_item_ids.length > 0) {
        dispatch('inspection_item/bulkDelete', diff_item_ids, {
          root: true,
        })
      }
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ dispatch }, id) {
    await api.delete('manage/inspections/' + id + '/')
    dispatch('clearAll')
  },
  getDuplicateData({ state }) {
    return new Promise((resolve, reject) => {
      if (!Object.keys(state.detail).length) {
        reject()
      }

      const data = state.detail
      delete data['id']
      delete data['calls']
      data['items'].forEach((item) => {
        item.original_id = item.id
        delete item.id
        delete item.type_name
      })

      data['name'] = data['name'] + '_copy'
      resolve(data)
    })
  },
}

function getDiffIds(current_data, form_data) {
  const ids = form_data.map((obj) => {
    if (obj.hasOwnProperty('id')) {
      return obj.id
    }
  })
  const current_ids = current_data.map((obj) => {
    if (obj.hasOwnProperty('id')) {
      return obj.id
    }
  })
  return current_ids.filter((x) => !ids.includes(x))
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
