<template>
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content text-body">
        <div class="modal-body">
          <div class="row g-1">
            <div class="col-10">
              <button
                type="button"
                class="btn btn-light me-1"
                @click.prevent="fileEvent"
              >
                <i class="bi bi-folder2 me-1"></i>ファイルを選択
              </button>
              <input
                type="file"
                ref="fileInput"
                @change="handleFileChange"
                class="d-none"
                :id="buttonId"
                :accept="accept"
              />
              <button
                class="btn btn-primary"
                type="button"
                v-on:click.prevent="uploadFile"
                :disabled="!selectedFile"
              >
                アップロード
              </button>
            </div>
            <div class="col-2 text-end">
              <button
                type="button"
                class="btn btn-link text-reset"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
            <div class="col-12">
              <div v-if="uploadProgress > 0">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ width: uploadProgress + '%' }"
                    :aria-valuenow="uploadProgress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ uploadProgress }}%
                  </div>
                </div>
              </div>
              <div v-if="uploadError" class="alert alert-danger mt-2">
                {{ uploadError }}
              </div>
            </div>
            <div class="col-12">
              <canvas
                ref="canvas"
                width="1600"
                height="900"
                style="display: none"
              ></canvas>
              <!-- 16:9 アスペクト比 -->
              <div class="ratio ratio-16x9 bg-secondary">
                <ImageViewer
                  :file="fileData"
                  :show_caption="false"
                  :show_lightbox="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { generateRandomFileName } from '@/utils/fileTypeUtils.js'
import { randomString } from '@/utils/stringUtils.js'
import ImageViewer from '@/components/AppImageViewer.vue'

export default {
  name: 'PreviewUpload',
  components: {
    ImageViewer,
  },

  props: {
    accept: {
      type: String,
      default: 'video/*, image/*',
    },
    maxSize: {
      type: Number,
      default: 500 * 1024 * 1024, // 500MB
    },
  },
  emits: ['uploadComplete', 'hiddenModal'],
  data() {
    return {
      modal: null,
      selectedFile: null,
      fileData: {},
      chunkSize: 5 * 1024 * 1024, // 5MB
      uploadProgress: 0,
      uploadError: null,
    }
  },
  computed: {
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
    buttonId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.clearData()
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    generateRandomFileName,
    randomString,
    show() {
      this.modal.show()
    },
    fileEvent() {
      document.getElementById(this.buttonId).click()
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        if (file.size > this.maxSize) {
          alert('The file size exceeds the limit of 500MB.')
          return
        }
        this.selectedFile = file

        if (new RegExp(/image/).test(file.type.toLowerCase())) {
          this.createImageThumbnail()
        }
        if (new RegExp(/video|mpegurl/).test(file.type.toLowerCase())) {
          this.createVideoThumbnail()
        }
      }
    },
    createImageThumbnail() {
      const reader = new FileReader()
      reader.onload = (event) => {
        this.fileData = {
          file_path: event.target.result,
          file_type: this.selectedFile.type,
        }
      }
      reader.readAsDataURL(this.selectedFile)
    },
    createVideoThumbnail() {
      const video = document.createElement('video')
      video.src = URL.createObjectURL(this.selectedFile)
      video.currentTime = 1 // 1秒の位置からサムネイルを作成

      video.addEventListener('loadeddata', () => {
        const canvas = this.$refs.canvas
        const context = canvas.getContext('2d')
        context.drawImage(video, 0, 0, canvas.width, canvas.height)
        this.fileData = {
          file_path: canvas.toDataURL('image/png'),
          file_type: 'image/png',
        }
      })
    },
    async uploadFile() {
      if (!this.selectedFile) return

      const totalChunks = Math.ceil(this.selectedFile.size / this.chunkSize)
      const filename = this.generateRandomFileName(this.selectedFile.type)
      this.uploadProgress = 0
      this.uploadError = null

      for (let i = 0; i < totalChunks; i++) {
        const start = i * this.chunkSize
        const end = Math.min(start + this.chunkSize, this.selectedFile.size)
        const chunk = this.selectedFile.slice(start, end)

        const formData = new FormData()
        formData.append('file', chunk)
        formData.append('chunk_number', i)
        formData.append('total_chunks', totalChunks)
        formData.append('filename', filename)

        try {
          const response = await api.post(
            'manage/item-images/upload/',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                )
                this.uploadProgress = Math.round(
                  ((i + progress / 100) / totalChunks) * 100
                )
              },
            }
          )
          if (response.data.is_complete) {
            // アップロードが完了した場合、親コンポーネントに通知
            this.$emit('uploadComplete', response.data)
            this.modal.hide()
            break
          }
        } catch (error) {
          console.error(`Error uploading chunk ${i + 1}/${totalChunks}:`, error)
          this.clearData()
          this.uploadError = `Error uploading chunk ${i + 1}: ${error.message}`
          break // エラーが発生した場合、アップロードを中断
        }
      }
      if (!this.uploadError) {
        this.clearData()
      }
    },
    clearData() {
      this.selectedFile = null
      this.fileData = {}
      this.uploadProgress = 0
      this.uploadError = null
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = ''
      }
    },
  },
}
</script>
