<template>
  <Navbar>
    <template v-slot:navbarTitle>エクスポート管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0">
        <i class="bi bi-cloud-download me-1"></i>エクスポート一覧
      </h5>
    </div>

    <div class="d-flex justify-content-end gap-2 mb-2">
      <Pagination :prev="prev" :next="next" @move-page="movePage" />
    </div>

    <!-- results -->
    <div v-if="isLoading && results && results.length > 0">
      <data-table :headers="headers" :items="results">
        <template #file_path="{ item }">
          <span v-if="item.file_path">
            <a :href="item.file_path" target="_blank">ダウンロード</a> </span
          ><span v-else><i class="bi bi-arrow-repeat me-1"></i>変換中</span>
        </template>
        <template #file_size="{ item }">
          <span v-if="item.file_size">{{ item.file_size }} バイト</span>
        </template>
        <template #actions="{ item }">
          <a
            v-on:click.prevent="removeData(item.id)"
            href="#"
            class="text-danger text-decoration-none"
            >削除</a
          >
        </template>
      </data-table>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import Confirm from '@/components/TheConfirm.vue'
import DataTable from '@/components/common/DataTable.vue'

export default {
  name: 'ExportDataList',
  components: {
    Navbar,
    Pagination,
    Confirm,
    DataTable,
  },

  data() {
    return {
      isLoading: false,
      headers: [
        {
          key: 'application',
          label: 'データ名',
          class: 'col-2',
          format: 'name',
        },
        {
          key: 'file_path',
          label: 'ファイル',
          class: 'col-2',
          format: 'name',
        },
        {
          key: 'file_size',
          label: 'ファイルサイズ',
          class: 'col-1',
          format: 'number',
        },
        {
          key: 'created_by',
          label: '操作ユーザー',
          class: 'col-2',
          format: 'name',
        },
        {
          key: 'created_at',
          label: '変換指示日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'updated_at',
          label: '変換完了日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'actions',
          label: '',
          class: 'col-1',
          format: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      results: 'export_data/results',
      prev: 'export_data/prev',
      next: 'export_data/next',
    }),
  },

  async created() {
    await this.getData()
    this.isLoading = true
  },

  methods: {
    ...mapActions({
      getData: 'export_data/fetchAll',
      deleteData: 'export_data/deleteData',
    }),
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    removeData(id) {
      this.currentId = id
      this.$refs.confirmDataDelete.showConfirm(
        '',
        'このファイルを削除しますか？'
      )
    },
    async confirmDataDelete() {
      try {
        await this.deleteData(this.currentId)
      } catch (e) {
        let message = '削除できませんでした。'
        this.$store.dispatch('alert/setErrorMessage', {
          message: message,
        })
      }
      this.currentId = null
    },
  },
}
</script>
