<template>
  <Navbar>
    <template v-slot:navbarTitle>ユーザー管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-person me-1"></i>ユーザー</h5>
      <div class="ms-auto">
        <button
          type="button"
          v-on:click.prevent="showUserCreateForm()"
          class="btn btn-primary"
        >
          <span class="d-block d-sm-none"><i class="bi bi-plus-lg"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-plus-lg me-1"></i>ユーザーを追加する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <search-form
        :query="query"
        query-key="username"
        placeholder="ユーザー名で検索"
        @update-search-query="onUpdateSearchQuery"
        @open-search-modal="onOpenSearchModal"
      ></search-form>
      <Pagination :prev="prev" :next="next" @move-page="movePage" />
    </div>

    <!-- users -->
    <div v-if="users && users.length > 0">
      <data-table :headers="dataHeaders" :items="users">
        <template #groups="{ item }">
          {{ item.groups.join(', ') }}
        </template>
        <template #is_active="{ item }">
          <ActiveStatus :is_active="item.is_active" />
        </template>
        <template #actions="{ item }">
          <span v-if="item.id != userData('id')">
            <a
              href="#"
              v-on:click.prevent="showUserEditForm(item)"
              class="text-success text-decoration-none"
              >編集</a
            >｜<a
              href="#"
              v-on:click.prevent="removeData(item)"
              class="text-danger text-decoration-none"
              >削除</a
            >
          </span>
        </template>
      </data-table>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>

    <user-form ref="userCreateForm" @after-submit="getQueryResult">
      <template #modalTitle>ユーザーを追加する</template>
      <template #submitButton>追加</template>
    </user-form>
    <user-form ref="userUpdateForm" @after-submit="getQueryResult">
      <template #modalTitle>ユーザーを編集する</template>
      <template #submitButton>編集</template>
    </user-form>
    <DeleteDialog ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import ActiveStatus from '@/components/TheActiveStatus.vue'
import DeleteDialog from '@/components/AppDeleteDialog.vue'
import DataTable from '@/components/common/DataTable.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'
import UserForm from '@/components/manage/UserForm.vue'

export default {
  name: 'UserList',
  components: {
    Navbar,
    Pagination,
    ActiveStatus,
    DeleteDialog,
    DataTable,
    SearchForm,
    SearchModal,
    UserForm,
  },

  data() {
    return {
      currentId: null,
      query: {},
      dataHeaders: [
        {
          key: 'full_name',
          label: '氏名',
          class: 'col-2',
          format: 'string',
        },
        {
          key: 'username',
          label: 'ユーザー名',
          class: 'col-2',
          format: 'string',
        },
        {
          key: 'groups',
          label: '権限グループ',
          class: 'col-2',
          format: 'string',
        },
        {
          key: 'is_active',
          label: '有効',
          class: 'col-1',
          format: 'status',
        },
        {
          key: 'date_joined',
          label: '作成日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'last_login',
          label: '最終ログイン日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'actions',
          label: '',
          class: 'col-sm-1',
          format: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      users: 'user/users',
      count: 'user/count',
      prev: 'user/prev',
      next: 'user/next',
      current_page: 'user/current_page',
      total_pages: 'user/total_pages',
      userData: 'auth/userData',
      groups: 'master/groups',
    }),
    searchItems() {
      return [
        {
          id: 'full_name',
          label: '氏名',
          type: 'text',
        },
        {
          id: 'username',
          label: 'ユーザー名',
          type: 'text',
        },
        {
          id: 'group_id',
          label: '権限グループ',
          type: 'select',
          options: this.groups.map((item) => {
            return {
              value: item.id,
              name: item.name,
            }
          }),
        },
        {
          id: 'is_active',
          label: '有効',
          type: 'checkbox',
        },
      ]
    },
  },

  async created() {
    await this.getData()
  },

  methods: {
    ...mapActions({
      getData: 'user/fetchAll',
      deleteData: 'user/deleteData',
    }),
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.getData(this.query)
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    onUpdateSearchQuery(query = {}) {
      this.query = query
      this.getQueryResult()
    },
    showUserCreateForm() {
      this.$refs.userCreateForm.showModal()
    },
    showUserEditForm(data) {
      this.$refs.userUpdateForm.showModal(data)
    },
    removeData(object) {
      this.currentId = object.id
      this.$refs.confirmDataDelete.showDialog('ユーザーデータ', object.username)
    },
    async confirmDataDelete() {
      if (this.currentId == this.userData('id')) {
        return
      }

      try {
        await this.deleteData(this.currentId)
      } catch (e) {
        if (e.response.status == 400) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: e.response.data,
          })
        }
      }
      this.currentId = null
    },
  },
}
</script>
