<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="handleQRCodeData"
    @cancel="cancelCodeCamera"
  />

  <div v-else>
    <Navbar>
      <template v-slot:navbarButton>
        <router-link
          :to="{
            name: 'Inspection',
          }"
          class="btn btn-dark"
          >戻る</router-link
        >
      </template>
    </Navbar>

    <main id="main" class="bg-light">
      <div class="container pt-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">指示検査開始</h5>
            <h6 class="card-subtitle mb-2 text-muted"></h6>
            <hr />
            <div class="row">
              <label class="text-muted mb-1" for="inspection_plan_number"
                >指示番号</label
              >
              <div class="col">
                <input
                  v-model="query.inspection_plan_number"
                  type="text"
                  class="form-control form-control-lg"
                  id="inspection_plan_number"
                />
              </div>
              <div class="col-1">
                <button
                  class="btn btn-lg btn-light"
                  v-on:click.prevent="startCodeCamera"
                >
                  <i class="bi bi-qr-code"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container pt-5">
        <div class="row row-cols-1 g-3">
          <div class="col">
            <button
              class="btn btn-lg btn-dark w-100 py-4"
              v-on:click.prevent="next()"
            >
              次へ
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { trimToDigits } from '@/utils/stringUtils.js'
import CodeReader from '@/components/AppCodeReader.vue'
import Navbar from '@/apps/inspection/operate/components/InspectionNavbar.vue'

export default {
  name: 'InspectionPlan',
  components: {
    Navbar,
    CodeReader,
  },

  data() {
    return {
      query: {
        inspection_plan_number: '',
      },
      showCodeCamera: false,
    }
  },

  created() {
    this.clearAll()
  },

  methods: {
    trimToDigits,
    ...mapActions({
      setQuery: 'inspection_plan/setQuery',
      clearAll: 'inspection_plan/clearAll',
    }),
    startCodeCamera() {
      this.showCodeCamera = true
    },
    cancelCodeCamera() {
      this.showCodeCamera = false
    },
    handleQRCodeData(result) {
      this.query.inspection_plan_number = this.trimToDigits(result, 6)
      this.showCodeCamera = false
    },
    next() {
      if (!this.query.inspection_plan_number) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: '指示番号を入力してください。',
        })
      } else {
        this.setQuery(this.query).then(() => {
          this.$router.push({
            name: 'InspectionPlanDetail',
          })
        })
      }
    },
  },
}
</script>
