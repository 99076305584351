import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  detail: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  detail: (state) => state.detail,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearDetail')
      resolve()
    })
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ commit }, formData) {
    // 必要なデータのみにする
    const requiredKeys = [
      'item_id',
      'file_path',
      'file_name',
      'file_type',
      'file_size',
    ]
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key]) => requiredKeys.includes(key))
    )

    try {
      const response = await api.post('manage/item-images/', submitData)
      commit('setDetail', response.data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ commit }, id) {
    try {
      await api.delete('manage/item-images/' + id + '/')
      commit('clearDetail')
    } catch (error) {
      console.log(error)
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
