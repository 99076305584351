import { createRouter, createWebHistory } from 'vue-router'

import AppHome from '@/views/AppHome.vue'
import AppHomeManage from '@/views/AppHomeManage.vue'
import AppHomeOperate from '@/views/AppHomeOperate.vue'
import AppLogin from '@/views/AppLogin.vue'
import NotFound from '@/views/NotFound.vue'

import User from './user'
import Item from './item'
import ItemPrice from './item_price'
import Client from './client'
import Supplier from './supplier'
import ExportData from './export_data'

import ManageInspection from '@/apps/inspection/manage/router'
import ManageInspectionPlan from '@/apps/inspection_plan/manage/router'
import ManageInventory from '@/apps/inventory/manage/router'
import ManageOrder from '@/apps/order/manage/router'
import ManagePurchase from '@/apps/purchase/manage/router'

import OperateInspection from '@/apps/inspection/operate/router'
import OperateInspectionPlan from '@/apps/inspection_plan/operate/router'
import OperateInventory from '@/apps/inventory/operate/router'
import OperateOrder from '@/apps/order/operate/router'
import OperatePurchase from '@/apps/purchase/operate/router'

// store
import store from '@/store'

const routes = [
  {
    path: '/',
    component: AppHome,
    name: 'AppHome',
    meta: { requiresAuth: true },
  },
  {
    path: '/manage',
    component: AppHomeManage,
    name: 'AppHomeManage',
    meta: { requiresAuth: true },
  },
  {
    path: '/operate',
    component: AppHomeOperate,
    name: 'AppHomeOperate',
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    component: AppLogin,
    name: 'AppLogin',
  },
  ...User.routes,
  ...Item.routes,
  ...ItemPrice.routes,
  ...Client.routes,
  ...Supplier.routes,
  ...ExportData.routes,
  ...ManageInspection.routes,
  ...ManageInspectionPlan.routes,
  ...ManageInventory.routes,
  ...ManageOrder.routes,
  ...ManagePurchase.routes,
  ...OperateInspection.routes,
  ...OperateInspectionPlan.routes,
  ...OperateInventory.routes,
  ...OperateOrder.routes,
  ...OperatePurchase.routes,
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters['auth/is_logged_in']) {
    if (store.getters['auth/is_token']) {
      store.dispatch('auth/renew').then(() => {
        next()
      })
    } else {
      router.replace({
        name: 'AppLogin',
        query: { redirect: to.fullPath },
      })
    }
  } else {
    next()
  }
})

export default router
