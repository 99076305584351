import OperateInspectionHome from './views/Home.vue'
import OperateInspection from './views/Inspection.vue'
import OperateInspectionCode from './views/InspectionCode.vue'
import OperateInspectionCall from './views/InspectionCall.vue'
import OperateInspectionStart from './views/InspectionStart.vue'
import OperateInspectionRestart from './views/InspectionRestart.vue'
import OperateInspectionResult from './views/InspectionResult.vue'
import OperateInspectionResume from './views/InspectionResume.vue'
import OperateInspectionEnd from './views/InspectionEnd.vue'
import OperateInspectionTemp from './views/InspectionTemp.vue'
import OperateInspectionTempDetail from './views/InspectionTempDetail.vue'

export default {
  routes: [
    {
      path: '/operate/inspection',
      component: OperateInspectionHome,
      name: 'InspectionHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'inspections',
          component: OperateInspection,
          name: 'Inspection',
        },
        {
          path: 'code',
          component: OperateInspectionCode,
          name: 'InspectionCode',
        },
        {
          path: 'calls',
          component: OperateInspectionCall,
          name: 'InspectionCall',
        },
        {
          path: 'start',
          component: OperateInspectionStart,
          name: 'InspectionStart',
        },
        {
          path: 'result',
          component: OperateInspectionResult,
          name: 'InspectionResult',
        },
        {
          path: 'resume',
          component: OperateInspectionResume,
          name: 'InspectionResume',
        },
        {
          path: 'end',
          component: OperateInspectionEnd,
          name: 'InspectionEnd',
        },
        {
          path: 'restart',
          component: OperateInspectionRestart,
          name: 'InspectionRestart',
        },
        {
          path: 'temps',
          component: OperateInspectionTemp,
          name: 'InspectionTemp',
        },
        {
          path: 'temps/:id([0-9]+)',
          component: OperateInspectionTempDetail,
          name: 'InspectionTempDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },
  ],
}
