<template>
  <Navbar />

  <div class="container pt-4">
    <div class="row row-cols-2 g-3">
      <div v-if="activeApp('inspection_plan')" class="col">
        <router-link
          :to="{
            name: 'InspectionPlan',
          }"
          class="btn btn-lg btn-primary w-100 py-3"
          ><i class="bi bi-play-circle-fill d-block" style="font-size: 2rem"></i
          >指示検査開始</router-link
        >
      </div>
      <div v-if="activeApp('inspection_plan')" class="col">
        <router-link
          :to="{
            name: 'InspectionPlanRestart',
          }"
          class="btn btn-lg btn-outline-primary w-100 py-3"
          ><i
            class="bi bi-skip-forward-circle-fill d-block"
            style="font-size: 2rem"
          ></i
          >指示検査再開</router-link
        >
      </div>
      <div v-if="activeApp('inspection')" class="col">
        <router-link
          :to="{
            name: 'InspectionCode',
          }"
          class="btn btn-lg btn-secondary w-100 py-3"
          ><i class="bi bi-play-circle-fill d-block" style="font-size: 2rem"></i
          >個別検査開始</router-link
        >
      </div>
      <div v-if="activeApp('inspection')" class="col">
        <router-link
          :to="{
            name: 'InspectionRestart',
          }"
          class="btn btn-lg btn-outline-secondary w-100 py-3"
          ><i
            class="bi bi-skip-forward-circle-fill d-block"
            style="font-size: 2rem"
          ></i
          >個別検査再開</router-link
        >
      </div>
      <div class="col">
        <router-link
          :to="{
            name: 'AppHomeOperate',
          }"
          class="btn btn-lg btn-outline-dark w-100 py-3"
          ><i class="bi bi-house d-block" style="font-size: 2rem"></i
          >ホームに戻る</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navbar from '../components/InspectionNavbar.vue'

export default {
  name: 'Inspection',
  components: {
    Navbar,
  },

  computed: {
    ...mapGetters({
      activeApp: 'auth/activeApp',
    }),
  },

  created() {
    this.$store.dispatch('clearDataStores')
  },
}
</script>
