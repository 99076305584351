import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  detail: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  detail: (state) => state.detail,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      resolve()
    })
  },
  async fetchAll({ commit }, query_params) {
    // パラメーターの値が空の場合は削除する
    Object.keys(query_params).forEach(
      (key) => query_params[key] === '' && delete query_params[key]
    )
    const response = await api.get('dashboard/inspection_plans/', {
      params: query_params,
    })
    commit('setData', response.data)
  },
  async fetchData({ state, commit }, id) {
    if (Object.keys(state.detail).length > 0 && state.detail.id == id) {
      return
    }

    const response = await api.get('dashboard/inspection_plans/' + id + '/')
    commit('setDetail', response.data)
  },
  async postImportData({}, form_data) {
    await api.post('dashboard/inspection_plans/import/', form_data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
