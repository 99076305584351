<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="onDecodeCodeData"
    @cancel="onCancelCodeCamera"
    class="animate__animated animate__fadeIn"
  />

  <div v-else>
    <Navbar>
      <template v-slot:navbarTitle>在庫管理</template>
    </Navbar>

    <main id="main" class="container-fluid pt-3">
      <div class="d-flex gap-2 mb-3">
        <h5 class="m-0 p-0">
          <i class="bi bi-card-checklist me-1"></i>ピッキングリスト
        </h5>
        <div class="ms-auto">
          <router-link
            :to="{
              name: 'ManagePickingListImport',
            }"
            class="btn btn-dark me-1 me-lg-2"
            ><span class="d-block d-sm-none"
              ><i class="bi bi-cloud-upload"></i
            ></span>
            <span class="d-none d-sm-block"
              ><i class="bi bi-cloud-upload me-1"></i
              >CSVから一括で登録する</span
            ></router-link
          >
          <router-link
            :to="{
              name: 'ManagePickingListCreate',
            }"
            class="btn btn-primary"
            ><span class="d-block d-sm-none"
              ><i class="bi bi-plus-lg"></i
            ></span>
            <span class="d-none d-sm-block"
              ><i class="bi bi-plus-lg me-1"></i
              >ピッキングリストを作成する</span
            ></router-link
          >
        </div>
      </div>

      <div class="d-flex justify-content-start gap-2 mb-2">
        <search-form
          :query="query"
          query-key="code"
          placeholder="管理コードで検索"
          :use-code-reader="true"
          @update-search-query="onUpdateSearchQuery"
          @start-code-camera="onStartCodeCamera"
          @open-search-modal="onOpenSearchModal"
        ></search-form>
        <Pagination :prev="prev" :next="next" @move-page="movePage" />
      </div>

      <!-- pickingLists -->
      <div v-if="pickingLists && pickingLists.length > 0">
        <data-table :headers="dataHeaders" :items="pickingLists">
          <template #code="{ item }">
            <a
              v-if="!item.picked_by"
              href="#"
              @click="showPickingListForm(item)"
              >{{ item.code }}</a
            >
            <span v-else>{{ item.code }}</span>
          </template>
          <template #picked_at="{ item }">
            <span v-if="!item.picked_by"></span>
            <span v-else>{{ item.picked_at }}</span>
          </template>
        </data-table>
      </div>
      <p v-else class="mt-3">該当するデータはありません。</p>

      <!-- Modal -->
      <search-modal
        :query="query"
        :items="searchItems"
        @update-search-query="onUpdateSearchQuery"
        ref="searchModal"
      ></search-modal>

      <PickingListForm
        ref="pickingListForm"
        @after-submit="getQueryResult"
        @after-delete="getQueryResult"
      >
        <template v-slot:submitButton>保存</template>
      </PickingListForm>
    </main>
  </div>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import CodeReader from '@/components/AppCodeReader.vue'
import DataTable from '@/components/common/DataTable.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'
import PickingListForm from '../components/PickingListForm.vue'

export default {
  name: 'ManagePickingList',
  components: {
    Navbar,
    Pagination,
    CodeReader,
    DataTable,
    SearchForm,
    SearchModal,
    PickingListForm,
  },

  data() {
    return {
      query: {},
      showCodeCamera: false,
      dataHeaders: [
        {
          key: 'code',
          label: '管理コード',
          class: 'col-1',
          format: 'code',
        },
        {
          key: 'deadline',
          label: '納期',
          class: 'col-1',
          format: 'date',
        },
        {
          key: 'stock_code',
          label: '品目コード',
          class: 'col-1',
          format: 'code',
        },
        {
          key: 'stock_item_model',
          label: '型式',
          class: 'col-1',
          format: 'code',
        },
        {
          key: 'stock_name',
          label: '品目名',
          class: 'col-2',
          format: 'string',
        },
        {
          key: 'stock_location_name',
          label: '保管場所',
          class: 'col-1',
          format: 'string',
        },
        {
          key: 'quantity',
          label: '指示数量',
          class: 'col-1',
          format: 'number',
        },
        {
          key: 'memo',
          label: '指示補足',
          class: 'col-1',
          format: 'string',
        },
        {
          key: 'changed_quantity',
          label: '作業数量',
          class: 'col-1',
          format: 'number',
        },
        {
          key: 'changed_memo',
          label: '作業補足',
          class: 'col-1',
          format: 'string',
        },
        {
          key: 'created_by',
          label: '作成者',
          class: 'col-1',
          format: 'username',
        },
        {
          key: 'picked_by',
          label: '作業者',
          class: 'col-1',
          format: 'username',
        },
        {
          key: 'picked_at',
          label: '作業日時',
          class: 'col-1',
          format: 'datetime',
        },
      ],
    }
  },
  computed: {
    pickingLists() {
      return store.getters['manage_picking_list/results']
    },
    prev() {
      return store.getters['manage_picking_list/prev']
    },
    next() {
      return store.getters['manage_picking_list/next']
    },
    searchItems() {
      return [
        {
          id: 'code',
          label: '管理コード（完全一致）',
          type: 'text',
        },
        {
          id: 'deadline',
          label: '納期',
          type: 'date',
        },
      ]
    },
  },

  async created() {
    await this.getData()
  },

  methods: {
    getData(query = {}) {
      store.dispatch('manage_picking_list/fetchAll', query)
    },
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.getData(this.query)
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    onUpdateSearchQuery(query = {}) {
      this.query = query
      this.getQueryResult()
    },
    onStartCodeCamera() {
      this.showCodeCamera = true
    },
    onCancelCodeCamera() {
      this.showCodeCamera = false
    },
    onDecodeCodeData(result) {
      this.query.code = result
      this.showCodeCamera = false
    },
    showPickingListForm(item = {}) {
      this.$refs.pickingListForm.show(item)
    },
  },
}
</script>
