<template>
  <Navbar>
    <template v-slot:navbarButton>
      <router-link
        :to="{
          name: 'InspectionRestart',
        }"
        class="btn btn-dark"
        >戻る</router-link
      >
    </template>
  </Navbar>

  <main id="main" class="bg-light">
    <div class="container pt-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">検査データ一覧</h5>
          <h6 class="card-subtitle mb-2 text-muted"></h6>
          <hr />

          <!-- temps -->
          <div v-if="results && results.length > 0">
            <div class="table-responsive">
              <table class="table table-bordered bg-white">
                <thead>
                  <tr>
                    <th scope="col" class="col-sm-2">検査番号</th>
                    <th scope="col" class="col-sm-2">検査種別</th>
                    <th scope="col" class="col-sm-6">検査シート</th>
                    <th scope="col" class="col-sm-2">保存日時</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(result, index) in results" :key="index">
                    <td scope="row" class="text-center">
                      <router-link
                        :to="{
                          name: 'InspectionTempDetail',
                          params: { id: result.id },
                        }"
                        >{{ result.inspection_result_number }}</router-link
                      >
                    </td>
                    <td class="text-center">
                      {{ result.inspection_type_name }}
                    </td>
                    <td>{{ result.inspection_name }}</td>
                    <td class="text-center">{{ result.created_at }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <Pagination
                :count="count"
                :prev="prev"
                :next="next"
                :current_page="current_page"
                :total_pages="total_pages"
                :page_range="0"
                :show_count="true"
                @move-page="movePage"
              />
            </div>
          </div>
          <p v-else class="mt-3">該当するデータはありません。</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Pagination from '@/components/ThePagination.vue'
import Navbar from '../components/InspectionNavbar.vue'

export default {
  name: 'InspectionTemp',
  components: {
    Navbar,
    Pagination,
  },

  computed: {
    ...mapGetters({
      results: 'inspection_temp/results',
      count: 'inspection_temp/count',
      prev: 'inspection_temp/prev',
      next: 'inspection_temp/next',
      current_page: 'inspection_temp/current_page',
      total_pages: 'inspection_temp/total_pages',
      query: 'inspection_temp/query_params',
    }),
  },

  async created() {
    await this.getData()
  },

  methods: {
    ...mapActions({
      setQuery: 'inspection_temp/setQuery',
      getData: 'inspection_temp/fetchList',
    }),
    movePage(page) {
      this.setQuery(Object.assign(this.query, { page: page })).then(() => {
        this.getData()
      })
    },
  },
}
</script>
