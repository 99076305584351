<template>
  <Navbar>
    <template v-slot:navbarTitle>製品検査</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-tags me-1"></i>型式・型番一覧</h5>
      <div class="ms-auto">
        <router-link
          :to="{
            name: 'ManageInspectionCallImport',
          }"
          class="btn btn-primary me-1"
          ><i class="bi bi-cloud-upload me-2"></i
          >データをアップロードする</router-link
        >
        <button
          type="button"
          v-on:click.prevent="convertToCsvConfirm()"
          class="btn btn-dark"
        >
          <span class="d-block d-sm-none"
            ><i class="bi bi-arrow-repeat"></i
          ></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-arrow-repeat me-1"></i>データをCSVに変換する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <search-form
        :query="query"
        query-key="code"
        placeholder="型式・型番で検索"
        @update-search-query="onUpdateSearchQuery"
        @open-search-modal="onOpenSearchModal"
      ></search-form>
      <Pagination :prev="prev" :next="next" @move-page="movePage" />
    </div>

    <!-- calls -->
    <div v-if="calls && calls.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-3">型式・型番</th>
              <th scope="col" class="col-sm-2">検査種別</th>
              <th scope="col" class="col-sm-3">検査シート</th>
              <th scope="col" class="col-sm-3">作成日時</th>
              <th scope="col" class="col-sm-1"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="call in calls" :key="call.id">
              <td>{{ call.code }}</td>
              <td class="text-center">{{ call.inspection_type_name }}</td>
              <td>{{ call.inspection_name }}</td>
              <td class="text-end">{{ call.created_at }}</td>
              <td class="text-end">
                <a
                  href="#"
                  v-on:click.prevent="removeCall(call.id)"
                  class="text-danger text-decoration-none"
                  >削除</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>
  </main>

  <Confirm ref="confirmCallDelete" @confirm-true="confirmCallDelete" />
  <Confirm ref="confirmConvertData" @confirm-true="convertToCsv" />
</template>

<script>
import { mapActions } from 'vuex'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Confirm from '@/components/TheConfirm.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'

export default {
  name: 'ManageInspectionCall',
  components: {
    Navbar,
    Confirm,
    Pagination,
    SearchForm,
    SearchModal,
  },

  data() {
    return {
      query: {},
      current_call_id: null, // 削除時に使用
    }
  },

  computed: {
    calls() {
      return store.getters['inspection_call/results']
    },
    prev() {
      return store.getters['inspection_call/prev']
    },
    next() {
      return store.getters['inspection_call/next']
    },
    inspectionTypes() {
      let inspectionTypeList = store.getters['inspection_type/list']
      return inspectionTypeList.map((item) => {
        return {
          value: item.id,
          name: item.name,
        }
      })
    },
    searchItems() {
      return [
        {
          id: 'code',
          label: '型式・型番（前方一致）',
          type: 'text',
        },
        {
          id: 'inspection_type_id',
          label: '検査種別（完全一致）',
          type: 'select',
          options: this.inspectionTypes,
        },
        {
          id: 'inspection_name',
          label: 'シート名（前方一致）',
          type: 'text',
        },
      ]
    },
  },

  async created() {
    await this.fetchInspectionTypeList()
    await this.getData()
  },

  methods: {
    ...mapActions({
      postExportData: 'export_data/postExportData',
    }),
    fetchInspectionTypeList() {
      return store.dispatch('inspection_type/fetchList')
    },
    getData(query = {}) {
      return store.dispatch('inspection_call/fetchAll', query)
    },
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.getData(this.query)
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    onUpdateSearchQuery(query = {}) {
      this.query = query
      this.getQueryResult()
    },
    removeCall(call_id) {
      this.current_call_id = call_id
      this.$refs.confirmCallDelete.showConfirm(
        'データを削除',
        'この型式・型番を削除しますか？'
      )
    },
    async confirmCallDelete() {
      await store.dispatch('inspection_call/deleteData', this.current_call_id)
      let index = this.calls.findIndex(({ id }) => id === this.current_call_id)
      this.calls.splice(index, 1)
      this.current_call_id = null
    },
    convertToCsvConfirm() {
      this.$refs.confirmConvertData.showConfirm(
        'データを変換',
        'この内容でCSVに変換しますか？'
      )
    },
    convertToCsv() {
      const formData = {
        application: 'inspection-calls',
        conditions: Object.assign({}, this.query),
      }
      try {
        this.postExportData(formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message:
            'CSVファイルへの変換を開始しました。エクスポート管理からダウンロードしてください',
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
