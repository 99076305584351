<template>
  <div class="row row-cols-1 gy-3">
    <div class="col">
      <Camera @capture-func="setCaptureImage" />
    </div>
    <div class="col">
      <button
        class="btn btn-lg btn-success w-100 py-4"
        v-on:click.prevent="success()"
      >
        <i class="bi bi-check-circle-fill me-2"></i> 撮影しました
      </button>
    </div>
  </div>
</template>

<script>
import Camera from '@/components/TheCamera.vue'

export default {
  name: 'InspectionCamera',
  components: {
    Camera,
  },

  emits: ['resultFunc'],

  data() {
    return {
      photoData: null,
    }
  },

  methods: {
    success() {
      if (!this.photoData) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: '撮影してください。',
        })
      } else {
        this.$emit('resultFunc', {
          is_success: true,
          description: null,
          photo: this.photoData,
        })
      }
    },
    setCaptureImage(dataURL) {
      this.photoData = dataURL
    },
  },
}
</script>
