import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  detail: {},
  list: [],
  query: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  detail: (state) => state.detail,
  list: (state) => state.list,
  query: (state) => state.query,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
  setQuery: (state, query) => (state.query = query),
  clearQuery: (state) => (state.query = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      commit('clearList')
      commit('clearQuery')
      resolve()
    })
  },
  /**
   * データを取得する
   *
   * @return {void}
   */
  async fetchAll({ commit, state }) {
    const response = await api.get('manage/items/', {
      params: state.query,
    })
    commit('setData', response.data)
  },
  /**
   * データを1件取得する
   *
   * @param {number}
   * @return {void}
   */
  async fetchData({ commit }, id) {
    const response = await api.get('manage/items/' + id + '/')
    commit('setDetail', response.data)
  },
  /**
   * データをリスト形式で取得する
   *
   * @param {object}
   * @return {void}
   */
  async fetchList({ commit }, queryParams) {
    const response = await api.get('manage/items/list/', {
      params: queryParams,
    })
    commit('setList', response.data)
  },
  /**
   * データを追加または更新する
   *
   * @param {object}
   * @return {object}
   */
  async postOrPatch({ commit, dispatch }, formData) {
    try {
      // 必要なデータのみにする
      const requiredKeys = [
        'id',
        'code',
        'item_model',
        'name',
        'memo',
        'classifications',
      ]
      const submitData = Object.fromEntries(
        Object.entries(formData).filter(([key]) => requiredKeys.includes(key))
      )

      let response
      if ('id' in submitData && submitData['id']) {
        response = await dispatch('patchData', submitData)
      } else {
        response = await dispatch('postData', submitData)
        await commit('clearQuery')
        await dispatch('fetchAll')
      }
      return response
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData(_, formData) {
    try {
      const response = await api.post('manage/items/', formData)
      return response.data
    } catch (error) {
      console.error('Failed to post data:', error)
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({ commit }, formData) {
    try {
      const response = await api.patch(
        'manage/items/' + formData.id + '/',
        formData
      )
      commit('setDetail', response.data)
    } catch (error) {
      console.error('Failed to patch data:', error)
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {number}
   */
  async deleteData({ commit, dispatch }, id) {
    try {
      await api.delete('manage/items/' + id + '/')
      await commit('clearQuery')
      await dispatch('fetchAll')
      return id
    } catch (error) {
      console.error('Failed to delete data:', error)
      throw error
    }
  },
  /**
   * CSVファイルをインポートする
   *
   * @param {object}
   * @return {void}
   */
  async importData(_, formData) {
    try {
      await api.post('manage/items/import/', formData)
    } catch (error) {
      console.error('Failed to import data:', error)
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
